import React from "react"
import { Helmet } from "react-helmet"

const demoScript = props => {
  return (
    <Helmet>
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
      <script type="text/javascript" src="../how-to-use-jquery-rwd-image-maps/js/jquery.rwdImageMaps.min.js"></script>
      <script type="text/javascript" src="../how-to-use-jquery-rwd-image-maps/js/run.js"></script>
    </Helmet>
  )
}

export default demoScript