import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Script from "./script"

const howToUseJqueryRwdImageMaps = ({ data, location }) => (
<Layout>
  <Seo
    pagetitle="[DEMO] jQuery RWD Image Maps"
    pagedesc="クリッカブルマップ・イメージマップのレスポンシブ対応を実現するjQuery RWD Image Mapsのデモページです。"
    pagepath={location.pathname}
  />
  <Script />
  <div>
    <article className="content">
      <div className="container">
        <h1 className="bar">[DEMO] jQuery RWD Image Maps</h1>
        <div className="postbody">

        <figure>
          <img src="../how-to-use-jquery-rwd-image-maps/images/sample.jpg" useMap="#ImageMap" alt="" />
          <map name="ImageMap">
            <area shape="rect" coords="52,98,584,603" href="#" alt="矩形" />
            <area shape="circle" coords="939,351,284" href="#" alt="円円" />
            <area shape="poly" coords="1521,223,1629,47,1757,225,1954,279,1807,439,1833,634,1645,563,1428,645,1450,412,1302,280,1510,225" href="#" alt="星" />
          </map>
        </figure>

        <p><Link to={`/blog/post/how-to-use-jquery-rwd-image-maps/`}>記事に戻る</Link></p>

        </div>
      </div>
    </article>
  </div>
</Layout>
)

export default howToUseJqueryRwdImageMaps